//VNS
export const url0 = "https://pingyn.oss-cn-beijing.aliyuncs.com/2/error";
export const url1 = "http://154.23.178.6/2/error";
export const url2 = "https://pingyn.ks3-cn-beijing.ksyuncs.com/2/error";
export const url3 =
  "http://alb-f4r15f877eejshperb.ap-northeast-1.alb.aliyuncs.com/2/error";
export const url4 = "https://dbutdt.s3.ap-northeast-1.amazonaws.com/2/error";

export const VARIANT = "8801";
export const DOMAIN = "https://ly.qpdqdwebda.com";
export const AGENT = "88vns";
export const APP_NAME = "乐游棋牌";
